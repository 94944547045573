<template>
  <VLayout class="menu-layout" row wrap>
    <VFlex v-if="hasLegend" pl-3 sm6 hidden-sm-and-down>
      <MenuLegend />
    </VFlex>
    <VContainer v-if="menu.sections && menu.sections.length" px-0>
      <BaseEditable
        v-if="menu.title"
        tag="div"
        :content="menu.title"
        :mods="myMods.title"
        :class="[
          'menu-layout--title karla--font text-xs-center text-sm-left',
          ['xs', 'sm', 'md'].includes($mq) ? '' : 'px-4',
        ]"
        @update="updateMenu('title', $event)"
      />
      <component
        :is="['sm', 'xs'].includes($mq) ? 'div' : 'Isotope'"
        ref="isotope"
        class="menu-sections masonry-wrap"
        :list="sections"
        :options="{
          itemSelector: '.menu-section--wrap',
          getSortData: {
            order: 'order',
          },
          transitionDuration: 0,
        }"
      >
        <template v-for="(sectionId, i) of sections">
          <WithMenuSection
            :id="sectionId"
            v-slot="{
              isSectionLoading,
              section,
              itemMods,
              myMods: sectionMods,
            }"
            :key="sectionId"
          >
            <div
              :key="section.id"
              class="menu-section--wrap blue-giant--section-wrap"
            >
              <MenuSectionCallout
                v-if="section.is_callout"
                :ref="`section-${i}`"
                :key="section.id"
                :entity="section"
                :item-mods="itemMods"
                :my-mods="sectionMods"
              />
              <MenuSectionCover
                v-else-if="section.section_type === 'cover'"
                :ref="`section-${i}`"
                :key="section.id"
              />
              <BaseMenuComponent
                v-else
                :ref="`section-${i}`"
                :key="section.id"
                name="MenuSection"
                :entity="section"
                :item-mods="itemMods"
                :my-mods="sectionMods"
                @addSectionAfter="
                  addSectionAction({ parentId: menu.id, index: i + 1 })
                "
                @deleteSection="
                  removeSection({ parentId: menu.id, sectionId: section.id })
                "
                @isotopeRefresh="isotopeRefresh"
                @mounted="numLoadedSections++"
              />
            </div>
          </WithMenuSection>
        </template>
      </component>
    </VContainer>
    <VFlex v-if="hasLegend" xs12 hidden-md-and-up>
      <MenuLegend />
    </VFlex>
  </VLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import selectElementText from '@utils/select-element-text'
import _get from 'lodash/get'

import Isotope from 'vueisotope'

import WithMenuSection from '@dataProviders/WithMenuSection'
import MenuLegend from '@menu/MenuLegend'
import MenuSectionCover from '@menu/MenuSectionCover'
import MenuSectionCallout from '@menu/MenuSectionCallout'
import { ENTITY_NAMES } from '@constants/lookupTables'

export default {
  name: 'BlueGiantMenuLayout',
  components: {
    MenuSectionCallout,
    MenuSectionCover,
    MenuLegend,
    Isotope,
    WithMenuSection,
  },
  props: {
    menu: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      numLoadedSections: 0,
    }
  },

  computed: {
    ...mapGetters('entities', ['getEntityMods', 'getNonRemovedEntitySet']),
    ...mapGetters('editor', ['isShowingEditUI']),
    sections() {
      return this.menu.sections.filter((s) => !s.parent_id)
    }, // sections

    areAllSectionsLoaded() {
      return this.numLoadedSections >= this.sections.length
    }, // areAllSectionsLoaded

    meta_info() {
      return JSON.parse(this.menu.meta_info) || {}
    }, // meta_info
    hasLegend() {
      return _get(this.meta_info, 'has_legend', false)
    }, // hasLegend
    myMods() {
      return this.getEntityMods(ENTITY_NAMES.MENUS, this.menu.id)
    }, // myMods
  },
  watch: {
    areAllSectionsLoaded(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.isotopeRefresh()
        }, 100)
      }
    },
    isShowingEditUI(newVal) {
      setTimeout(() => {
        this.isotopeRefresh()
      }, 10)
    }, // isShowingEditUI
  },
  created() {
    setTimeout(() => this.isotopeRefresh(), 2000)
  }, // created()
  methods: {
    ...mapActions('menus', ['tryMenuMod']),
    ...mapActions('sections', ['stageNewSection', 'removeSection']),
    updateMenu(key, val) {
      this.tryMenuMod({
        entityName: ENTITY_NAMES.MENUS,
        entityId: this.menu.id,
        mods: { [key]: val },
      })
    }, // updateMenu

    async addSectionAction({ parentId, index }) {
      await this.stageNewSection({ parentId, index })
      let newSectionTitleEl = this.$refs[`section-${index}`][0].$refs['title']
        .$el
      newSectionTitleEl.focus()
      selectElementText(newSectionTitleEl)
    }, // addSectionAfterAction

    async isotopeRefresh() {
      let iso = this.$refs.isotope
      if (!!iso && typeof iso.sort === 'function') {
        iso.sort('order')
      }
    }, // isotopeRefresh
  }, // methods
}
</script>

<style lang="scss">
@import '@design';

.theme--BlueGiant .menu-layout--title {
  margin-bottom: 0.7em;
  font-size: 60px;
  font-weight: 700;
  line-height: 0.65;
  text-transform: uppercase;

  @media screen and (min-width: 960px) {
    margin-bottom: 1.1em;
    font-size: 75px;
  }
}

.menu-layout {
  .is-dirty {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: -7px;
      width: 5px;
      height: 100%;
      // bottom:-5px;
      // left:0;
      // width:100%;
      // height:5px;
      content: '';
      background-color: $color-db-orange;
    }
  }
}

.menu-sections {
  // TODO (ESS) : don't hardcode this.
  // this is to account for the px-3 applied on MenuSection
  margin-right: -16px;
  margin-left: -16px;
}

.masonry-wrap {
  // display:grid;
  // grid-template-columns:repeat( auto-fill, minmax( 50%, 1fr ) );
  // column-count:2;
}

.blue-giant--section-wrap {
  // display:inline-block;
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
  }
  // &:nth-child(odd) {
  //   float:right;
  // }
  // &:nth-child(even) {
  //   float:left;
  // }
}
</style>
