var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VLayout',{staticClass:"menu-layout",attrs:{"row":"","wrap":""}},[(_vm.hasLegend)?_c('VFlex',{attrs:{"pl-3":"","sm6":"","hidden-sm-and-down":""}},[_c('MenuLegend')],1):_vm._e(),(_vm.menu.sections && _vm.menu.sections.length)?_c('VContainer',{attrs:{"px-0":""}},[(_vm.menu.title)?_c('BaseEditable',{class:[
        'menu-layout--title karla--font text-xs-center text-sm-left',
        ['xs', 'sm', 'md'].includes(_vm.$mq) ? '' : 'px-4' ],attrs:{"tag":"div","content":_vm.menu.title,"mods":_vm.myMods.title},on:{"update":function($event){return _vm.updateMenu('title', $event)}}}):_vm._e(),_c(['sm', 'xs'].includes(_vm.$mq) ? 'div' : 'Isotope',{ref:"isotope",tag:"component",staticClass:"menu-sections masonry-wrap",attrs:{"list":_vm.sections,"options":{
        itemSelector: '.menu-section--wrap',
        getSortData: {
          order: 'order',
        },
        transitionDuration: 0,
      }}},[_vm._l((_vm.sections),function(sectionId,i){return [_c('WithMenuSection',{key:sectionId,attrs:{"id":sectionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isSectionLoading = ref.isSectionLoading;
          var section = ref.section;
          var itemMods = ref.itemMods;
          var sectionMods = ref.myMods;
return [_c('div',{key:section.id,staticClass:"menu-section--wrap blue-giant--section-wrap"},[(section.is_callout)?_c('MenuSectionCallout',{key:section.id,ref:("section-" + i),refInFor:true,attrs:{"entity":section,"item-mods":itemMods,"my-mods":sectionMods}}):(section.section_type === 'cover')?_c('MenuSectionCover',{key:section.id,ref:("section-" + i),refInFor:true}):_c('BaseMenuComponent',{key:section.id,ref:("section-" + i),refInFor:true,attrs:{"name":"MenuSection","entity":section,"item-mods":itemMods,"my-mods":sectionMods},on:{"addSectionAfter":function($event){return _vm.addSectionAction({ parentId: _vm.menu.id, index: i + 1 })},"deleteSection":function($event){return _vm.removeSection({ parentId: _vm.menu.id, sectionId: section.id })},"isotopeRefresh":_vm.isotopeRefresh,"mounted":function($event){_vm.numLoadedSections++}}})],1)]}}],null,true)})]})],2)],1):_vm._e(),(_vm.hasLegend)?_c('VFlex',{attrs:{"xs12":"","hidden-md-and-up":""}},[_c('MenuLegend')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }